import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import AllInOne from '../../../../../components/case-studies/AllInOne'
import MobileCarousel from '../../../../../components/case-studies/MobileCarousel'
import Footer from '../../../../../components/case-studies/Footer'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'

class Letenky extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Firemná webstránka Trustpay'}
          description={'Prípadová štúdia'}
          description2={'Obľúbená platobná spoločnosť nás oslovila s cieľom osviežiť ich web dizajn a UX, aby mohli neustále kráčať s dobou.'}
          text={'Redizajn webu s cieľom dostať ho na vyššiu úroveň a do modernejšieho vzhľadu, aby lepšie reprezentoval zámery klienta. To sa nakoniec aj podarilo. Pozrite sa, ako sme projekt uchopili.'}
          img={data.landingImage}
          pageMode={true}
          noShadow={true}
          what_we_done={[
            { text: 'facelift UX a UI' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (wordpress, php, javascript)' }
          ]} />

        <AboutProject
          title={'Moderná webstránka na mieru nevznikne sama'}
          text={'Vytvorenie webstránky na najvyššej úrovni si vyžaduje profesionálny prístup.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: 'Klient nás oslovil s projektom na čiastočný redizajn firemného webu, vylepšenie UX a UI elementov a jeho naprogramovanie do finálnej podoby na platforme <a class="blue-link" href="/wodpress-a-woocommerce">Wordpress</a> vrátane úprav na mieru. Najskôr sme si s klientom prešli všetky požiadavky, aby sme <b>pochopili jeho očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Úloha bola jasná. Naprogramovať funkčný, moderný, responzívny a najmä perfektne <a class="blue-link" href="/ux-ui-dizajn">intuitívny</a> web, ktorý dokáže získavať nových zákazníkov, aby využili ponuku platobných služieb. Zároveň musí mať najlepšiu technologickú úroveň <b>funkcií, rýchlosti a SEO</b>. V rámci úzkej spolupráce sme s klientom diskutovali, ako tieto plány zrealizovať.',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Pri facelifte webu sme sa najskôr pustili do UX analýzy, kde sme klientovi vysvetlili nedostatky v pôvodnom webe a ako ich efektívne odstrániť. Následne sme začali tvorbu nových grafických UI elementov a finálneho <a class="blue-link" href="/webdizajn">webdizajnu</a>. Pravidelne sme klientovi prezentovali výstupy <b>a diskutovali o nich</b>. Projekt sme finálne zverili do rúk našich programátorov.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením web optimalizujeme pre prehliadače, responzívnosť a SEO, pre čo najlepšie pozície na Googli. Odovzdaním webu spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Sledujeme správanie návštevníkov cez Hotjar a Google Analytics a na základe získaných dát klientom radíme <b>ako zvýšiť ich tržby</b>.',
            }
          ]}
        />

        <AllInOne
          className="blue"
          title={'Facelift dizajnu na mieru'}
          description={'Profesionálna webstránka sa bez neho nezaobíde.'}
          text={'Stránka Trustpay je stelesnením jednoduchého, no sofistikovaného a moderného webu. Návštevníci sa v ňom dokážu intuitívne orientovať, čomu napomáha kvalitný UX dizajn webu. Perfektná responzívnosť a mobile first prístup sú už len čerešničkou na torte.'}
          projectDetail={'/nase-prace/webstranky/firemne/trustpay'}//TODO
          images={data.webImages}
        />

        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="mobilnetelefony" />
          </div>
        </Reveal>


        <MobileCarousel
          description='Väčšina návštevníkov webstránky prichádza cez mobily. Myslíme na to.'
          items={data.mobileGallery.nodes}
          smallDescription="Vytvoriť responzívny web do posledného detailu je kľúčové, pretože určuje, či bude <a class='red-link' href='/ux-ui-dizajn'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na smartfónoch (mobile first), tabletoch a ďalších rozlíšeniach. <br/><br/>Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastný web a chce, aby mu prinášal čo najväčší zisk."
        />

        {/* <ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        /> */}

        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.trustpay.sk" target='_blank' className='link-secondary'>www.trustpay.sk</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Dentique'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/firemne/dentique/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/trustpay/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/trustpay/visual.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    webImages: allFile(filter: { relativePath: {regex: "/(case-studies/trustpay/webGallery/)/"} }, sort: {fields:name}) {
        nodes {
            childImageSharp {
                fluid( maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    mobileGallery: allFile(filter: { relativePath: {regex: "/(case-studies/trustpay/mobileGallery/)/"} }, sort: {fields:name}) {
        nodes {
            childImageSharp {
                fluid( maxWidth: 900) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
}
`

export default Letenky
